import React from 'react'
import GlobalStyle from '../styles/globalStyles'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SliceZone from '../components/SliceZone'
import ContactForm from '../components/ContactForm'

const ContactDetails = styled.div`
  grid-column: 2 / span 4;
  margin-top: 0;

  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 2 / span 4;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
  }

  > div {
    display: flex;
  }

  > div > div {
    margin-top: 0;
    margin-bottom: 0;
  }

  h2 {

  }

  h3 {
    font-size: 24px;
    font-weight: 400;
    margin: 10px 0;
  }

  p:first-of-type {
    margin-top: 10px;
  }
`

const Contactpage = ({data}) => {

  const prismicContent = data.allPrismicContactpage.edges[0]
  if (!prismicContent) return null
  const doc = prismicContent.node 

  return (
    <Layout>
      <GlobalStyle />
      <SEO title="Contact" />
      <ContactDetails>
        <SliceZone sliceZone={doc.data.body} />
      </ContactDetails>
      <ContactForm />
    </Layout>
  )
}

export default Contactpage

export const query = graphql`
query ContactQuery {
  allPrismicContactpage {
    edges {
      node {
        data {
          page_title {
            text
          }
          body {
            ... on PrismicContactpageBodyTable {
              id
              primary {
                table_title {
                  text
                }
              }
              items {
                column_1 {
                  html
                  text
                  raw
                }
                column_2 {
                  html
                  text
                  raw
                }
              }
              slice_type
            }
            ... on PrismicContactpageBodyText {
              id
              primary {
                content {
                  raw
                  text
                }
              }
              slice_type
            }
          }
        }
      }
    }
  }
}
`