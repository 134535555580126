import React from 'react'
import styled from 'styled-components'


const Form = styled.form`
  grid-column: 2 / span 4;
  
  @media ${(props) => props.theme.breakpoints.s} {
    grid-column: 6 / span 4;
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 8 / span 6;
  }
`

const Fieldset = styled.fieldset`
`

const Legend = styled.legend`
  font-size: 24px;
  font-weight: 400;
  margin: 10px 0;
`

const FormGroup = styled.div`
  margin-bottom: 16px;
`

const Label = styled.label`
  color: ${(props) => props.theme.color.dark}; 
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  margin-bottom: 4px;
`

const Input = styled.input`
  background: transparent;
  border: 1px solid rgba(0,0,0,0.12); 
  box-sizing: border-box;
  color: ${(props) => props.theme.color.dark}; 
  font-size: 16px;
  line-height: 1.2;
  display: block;
  padding: 10px 14px;
  width: 100%;
`

const TextArea = styled.textarea`
  background: transparent;
  border: 1px solid rgba(0,0,0,0.12); 
  min-height: 240px;
  width: 100%;
`

const Button = styled(Input)`
  background-color: ${(props) => props.theme.color.secondary};
  border: 1px solid ${(props) => props.theme.color.secondary};
  color: #fff; 
  padding: 10px 28px;
  width: auto;
`

const ContactForm = () => {

  return (
    <Form noValidate>
      <Fieldset>
        <Legend>Contactformulier</Legend>
        <p>Vul het onderstaande formulier in en wij komen zo snel mogelijk bij u terug.</p>
        <FormGroup className="formgroup--first-name">
          <Label for="name">Naam</Label>
          <Input type="text" name="name" id="name" />
        </FormGroup>
        <FormGroup className="formgroup--email">
          <Label for="email">E-mailadres</Label>
          <Input type="email" name="email" id="email" />
        </FormGroup>
        <FormGroup className="formgroup--message">
          <Label for="email">Bericht</Label>
          <TextArea name="message" id="message" />
        </FormGroup>
        <FormGroup>
          <Button type="button" value="Verstuur bericht"/>
        </FormGroup>
      </Fieldset>
    </Form>
  )

}

export default ContactForm